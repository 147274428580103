import { IconKey, IconAnalyze, IconSettingsAutomation, IconBriefcase, IconReceipt } from '@tabler/icons';
import { verifyUserHasAccess } from '../utils/rbac';

const icons = {
  IconKey, IconAnalyze, IconSettingsAutomation, IconBriefcase, IconReceipt
};

const Rotinas = (user) => {

  const rotinas = {
    id: 'rotinas',
    title: 'Rotinas Fiscais',
    type: 'group',
    children: [
      // Automações
      ...(verifyUserHasAccess(user, 'automacoes') ? [
        {
          id: 'automacoesCollapse',
          title: 'Automações',
          type: 'collapse',
          icon: icons.IconSettingsAutomation,
          breadcrumbs: false,
          children: [
            ...(verifyUserHasAccess(user, 'automacoes_dctfweb') ? [
              {
                id: 'ecac',
                title: 'DARF Unificado - DCTFWEB',
                type: 'item',
                url: '/rotinas/ecac',
                icon: icons.IconKey,
                breadcrumbs: false
              }
            ] : []),
            ...(verifyUserHasAccess(user, 'automacoes_das') ? [
              {
                id: 'das',
                title: 'Simples Nacional - DAS',
                type: 'item',
                url: '/rotinas/das',
                icon: icons.IconKey,
                breadcrumbs: false
              }
            ] : []),
          ].filter(Boolean)
        }
      ] : []),

      // Publicações
      ...(verifyUserHasAccess(user, 'publicacoes') ? [
        {
          id: 'publicacoesCollapse',
          title: 'Publicações',
          type: 'collapse',
          icon: icons.IconBriefcase,
          breadcrumbs: false,
          children: [
            ...(verifyUserHasAccess(user, 'publicacoes_impostos_mensais') ? [
              {
                id: 'mensais',
                title: 'Impostos Mensais - Acessorias',
                type: 'item',
                url: '/rotinas/ImpostosMensais',
                icon: icons.IconReceipt,
                breadcrumbs: false
              }
            ] : []),
            ...(verifyUserHasAccess(user, 'publicacoes_publicar_lote') ? [
              {
                id: 'publicarObrigacoes',
                title: 'Publicar em lote para consolidação',
                type: 'item',
                url: '/rotinas/publicarObrigacoes',
                icon: icons.IconReceipt,
                breadcrumbs: false
              }
            ] : []),
            ...(verifyUserHasAccess(user, 'publicacoes_impostos_conecta') ? [
              {
                id: 'impostosMensaisConecta',
                title: 'Impostos Mensais - Conecta',
                type: 'item',
                url: '/rotinas/impostosMensaisConecta',
                icon: icons.IconReceipt,
                breadcrumbs: false
              }
            ] : []),
          ].filter(Boolean)
        }
      ] : []),

      // Diagnóstico Fiscal Clientes
      ...(verifyUserHasAccess(user, 'diagnostico') ? [
        {
          id: 'clientPanel',
          title: 'Diagnostico Fiscal Clientes',
          type: 'item',
          url: '/rotinas/clientPanel',
          icon: icons.IconAnalyze,
          breadcrumbs: false
        }
      ] : [])
    ].filter(Boolean)
  };

  return rotinas;
};

export default Rotinas;
