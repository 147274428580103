import { IconUser, IconHeartHandshake, IconBusinessplan, IconUsers, IconPercentage, IconReceiptTax } from '@tabler/icons';
import { verifyUserHasAccess } from '../utils/rbac';

const icons = {
  IconUser,
  IconHeartHandshake,
  IconBusinessplan,
  IconUsers,
  IconPercentage,
  IconReceiptTax
};

const Configuracoes = (user) => {

  const configuracoes = {
    id: 'configuracoes',
    title: 'Configurações',
    type: 'group',
    children: [
      ...(verifyUserHasAccess(user, 'configuracoes_clientes') ? [
        {
          id: 'clientes',
          title: 'Clientes',
          type: 'item',
          url: '/configuracoes/clientes',
          icon: icons.IconHeartHandshake,
          breadcrumbs: false
        }
      ] : []),

      ...(verifyUserHasAccess(user, 'configuracoes_usuarios') ? [
        {
          id: 'usuarios',
          title: 'Usuários',
          type: 'item',
          url: '/configuracoes/usuario',
          icon: icons.IconUser,
          breadcrumbs: false
        }
      ] : []),

      ...(verifyUserHasAccess(user, 'configuracoes_colaboradores') ? [
        {
          id: 'colaboradores',
          title: 'Colaboradores',
          type: 'item',
          url: '/configuracoes/colaboradores',
          icon: icons.IconUsers,
          breadcrumbs: false
        }
      ] : []),

      ...(verifyUserHasAccess(user, 'configuracoes_departamentos') ? [
        {
          id: 'departamentos',
          title: 'Departamentos',
          type: 'item',
          url: '/configuracoes/departamentos',
          icon: icons.IconBusinessplan,
          breadcrumbs: false
        }
      ] : []),

      ...(verifyUserHasAccess(user, 'configuracoes_aliquotas') ? [
        {
          id: 'aliquotas',
          title: 'Aliquotas',
          type: 'item',
          url: '/configuracoes/aliquotas',
          icon: icons.IconPercentage,
          breadcrumbs: false
        }
      ] : []),

      ...(verifyUserHasAccess(user, 'configuracoes_empresa_servicos') ? [
        {
          id: 'empresaServicos',
          title: 'Empresas x Serviços',
          type: 'item',
          url: '/configuracoes/empresaServicos',
          icon: icons.IconPercentage,
          breadcrumbs: false
        }
      ] : []),

      ...(verifyUserHasAccess(user, 'configuracoes_tipo_obrigacao') ? [
        {
          id: 'tipoObrigacao',
          title: 'Relatórios (Tipo de Obrigação)',
          type: 'item',
          url: '/configuracoes/tipoObrigacao',
          icon: icons.IconReceiptTax,
          breadcrumbs: false
        }
      ] : [])
    ].filter(Boolean)
  };

  return configuracoes;
};

export default Configuracoes;
