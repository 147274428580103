import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';

export const formatCNPJ = (cnpj) => {
  const formartCNPJ = cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")

  if (!formartCNPJ) {
    return undefined
  }
  return formartCNPJ
}

export const validateCNPJ = (cnpj) => {
  cnpj = cnpj.replace(/\D/g, '');

  if (cnpj.length !== 14) {
    return false;
  }

  const areAllDigitsEqual = new Set(cnpj.split('')).size === 1;
  if (areAllDigitsEqual) {
    return false;
  }

  const calculateDigit = (cnpj, positions) => {
    let sum = 0;
    let digit = 0;

    for (let i = 0; i < positions.length; i++) {
      sum += parseInt(cnpj.charAt(i)) * positions[i];
    }

    sum = sum % 11;

    if (sum < 2) {
      digit = 0;
    } else {
      digit = 11 - sum;
    }

    return digit;
  };

  const firstDigit = calculateDigit(cnpj, [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]);
  const secondDigit = calculateDigit(cnpj, [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]);

  const calculatedDigits = `${firstDigit}${secondDigit}`;
  const providedDigits = cnpj.slice(-2);

  return calculatedDigits === providedDigits;
};

export const removeMascaraCNPJ = (cnpj) => {
  if (cnpj) {
    return cnpj.replace(/\D/g, '');
  }
  return '';
}

export const removeMascaraCPF = (cpf) => {
  if (cpf) {
    return cpf.replace(/\D/g, '');
  }
  return '';
}

export const formatCNPJMissingDigits = (cnpj) => {
  const totalDeDigitosObrigatorio = 14;
  let cnpjCompleto = cnpj

  if (cnpj.length < totalDeDigitosObrigatorio) {
    const totalDeDigitosFaltante = totalDeDigitosObrigatorio - cnpj.length
    for (let index = 0; index < totalDeDigitosFaltante; index++) {
      cnpjCompleto = '0' + cnpjCompleto
    }
  }
  return cnpjCompleto;
}

export const formatCPFMissingDigits = (cpf) => {
  const totalDeDigitosObrigatorio = 11;
  let cpfCompleto = cpf;

  if (cpf.length < totalDeDigitosObrigatorio) {
    const totalDeDigitosFaltante = totalDeDigitosObrigatorio - cpf.length;
    for (let index = 0; index < totalDeDigitosFaltante; index++) {
      cpfCompleto = '0' + cpfCompleto;
    }
  }
  return cpfCompleto;
};

export const validateCPF = (cpf) => {
  cpf = cpf.replace(/\D/g, '');

  if (cpf.length !== 11) {
    return false;
  }

  const areAllDigitsEqual = new Set(cpf.split('')).size === 1;
  if (areAllDigitsEqual) {
    return false;
  }

  const firstNineDigits = cpf.slice(0, 9);
  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(firstNineDigits[i]) * (10 - i);
  }
  let remainder = sum % 11;
  let firstVerificationDigit = remainder < 2 ? 0 : 11 - remainder;

  if (parseInt(cpf[9]) !== firstVerificationDigit) {
    return false;
  }

  const secondNineDigits = cpf.slice(0, 10);
  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(secondNineDigits[i]) * (11 - i);
  }
  remainder = sum % 11;
  let secondVerificationDigit = remainder < 2 ? 0 : 11 - remainder;

  return parseInt(cpf[10]) === secondVerificationDigit;
}

export const formatCPF = (value) => {
  const cleanedValue = value.replace(/\D/g, '');
  const unmaskedValue = cleanedValue.replace(/[^\d]/g, '');
  const maskedValue = unmaskedValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1-$2-$3/$4');
  return maskedValue;
};


export const validateCellphoneBrazil = (celular) => {
  const numeroLimpo = celular.replace(/[^\d]/g, '');

  if (numeroLimpo.length >= 10 && numeroLimpo.length <= 11) {
    return true; // Formato válido
  } else {
    return false; // Formato inválido
  }
}

export const formatCellphoneBrazil = (numero) => {
  if (numero) {
    const numeroLimpo = numero.replace(/[^\d]/g, '');

    const parte1 = numeroLimpo.slice(0, 2);
    const parte2 = numeroLimpo.slice(2, 7);
    const parte3 = numeroLimpo.slice(7, 11);

    return `(${parte1})${parte2}-${parte3}`;
  }

  return '';

}

export const validateEmailFormat = (email) => {
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return regexEmail.test(email);
}

export const validateFileTransaction = async (file) => {
  try {
    if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      toast.warning('Por favor, envie um arquivo XLSX.');
      return null;
    }

    return true
  } catch (error) {
    console.error('Erro ao carregar o arquivo:', error);
    toast.warning('Erro ao validar o arquivo. Verifique se o arquivo informado atende os critérios e tente novamente');
    return null;
  }
}

export const getCompaniesByWorksheetFile = async (file, user) => {
  try {
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, { type: 'binary', cellDates: true });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonOfCNPJS = XLSX.utils.sheet_to_json(worksheet, { blankrows: false });

    let cnpjs = []
    jsonOfCNPJS.map(item => {
      if (item.CNPJ) {
        cnpjs.push({
          Cnpj: formatCNPJMissingDigits(item.CNPJ.toString()),
          Nome: item.Empresa.toString()
        })
      }
    });

    if (cnpjs.length === 0) {
      toast.warning('Processo abortado. Não há CNPJs no arquivo. Verifique o arquivo e tente novamente');
      return null;
    }

    let cnpjsInvalidos = '';

    for (const item of cnpjs) {
      const cnpjToValidate = item.Cnpj;
      if (!validateCNPJ(cnpjToValidate)) {
        cnpjsInvalidos += cnpjToValidate + ',';
      }
    }

    cnpjsInvalidos = cnpjsInvalidos.slice(0, -1);

    if (cnpjsInvalidos) {
      toast.warning('Processo abortado. Há CNPJs inválidos no arquivo. CNPJs: ' + cnpjsInvalidos);
      return null;
    }

    let fileDataObject = {
      idUsuario: user?.id || user?._id,
      cnpj: cnpjs
    }

    if (fileDataObject) {
      return fileDataObject;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Erro ao ler o conteúdo do arquivo:', error);
    toast.warning('Erro ao ler o conteúdo. Verifique se o arquivo atende os critérios e tente novamente. Caso o erro persista, procure o administrador do sistema');
    return null;
  }
}

export const getMonths = () => {
  return [
    { id: 1, label: 'Janeiro', name: 'Janeiro' },
    { id: 2, label: 'Fevereiro', name: 'Fevereiro' },
    { id: 3, label: 'Março', name: 'Março' },
    { id: 4, label: 'Abril', name: 'Abril' },
    { id: 5, label: 'Maio', name: 'Maio' },
    { id: 6, label: 'Junho', name: 'Junho' },
    { id: 7, label: 'Julho', name: 'Julho' },
    { id: 8, label: 'Agosto', name: 'Agosto' },
    { id: 9, label: 'Setembro', name: 'Setembro' },
    { id: 10, label: 'Outubro', name: 'Outubro' },
    { id: 11, label: 'Novembro', name: 'Novembro' },
    { id: 12, label: 'Dezembro', name: 'Dezembro' },
  ];
}

export const getMonthsName = () => {
  return [
    { id: 'Janeiro', label: 'Janeiro' },
    { id: 'Fevereiro', label: 'Fevereiro' },
    { id: 'Março', label: 'Março' },
    { id: 'Abril', label: 'Abril' },
    { id: 'Maio', label: 'Maio' },
    { id: 'Junho', label: 'Junho' },
    { id: 'Julho', label: 'Julho' },
    { id: 'Agosto', label: 'Agosto' },
    { id: 'Setembro', label: 'Setembro' },
    { id: 'Outubro', label: 'Outubro' },
    { id: 'Novembro', label: 'Novembro' },
    { id: 'Dezembro', label: 'Dezembro' },
  ];
}

export const convertJsonToExcelDownload = (fileName, jsonData) => {

  const worksheet = XLSX.utils.json_to_sheet(jsonData)
  const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] }
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
  saveAsExcelFile(excelBuffer, fileName)
}

const saveAsExcelFile = (buffer, fileName) => {
  import('file-saver').then(module => {
    if (module && module.default) {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });

      module.default.saveAs(data, fileName + '_' + new Date().toLocaleString() + EXCEL_EXTENSION);
    }
  });
}

export const validateNewPassword = (senha, confirmacaoSenha) => {


  if (senha !== confirmacaoSenha) {
    return { passwordOK: false, message: 'As senhas não são iguais.' };
  }

  if (senha.length < 8) {
    return { passwordOK: false, message: 'A senha deve ter pelo menos 8 caracteres.' };
  }

  if (!/[A-Z]/.test(senha)) {
    return { passwordOK: false, message: 'A senha deve conter pelo menos uma letra maiúscula.' };
  }

  // Verifica se a senha contém pelo menos uma letra minúscula
  if (!/[a-z]/.test(senha)) {
    return { passwordOK: false, message: 'A senha deve conter pelo menos uma letra minúscula.' };
  }

  // Verifica se a senha contém pelo menos um número
  if (!/\d/.test(senha)) {
    return { passwordOK: false, message: 'A senha deve conter pelo menos um número.' };
  }

  return { passwordOK: true, message: '' };
}

export const formatCurrencyBrazil = (value) => {

  const numberValue = parseFloat(value);

  if (numberValue) {
    const valueFormat = (numberValue ?? 0).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });

    if (valueFormat) {
      return valueFormat;
    } else {
      return ''
    }
  } else {
    return ''
  }
}

export const downloadBase64File = async (base64Data, fileName) => {
  const blob = await base64ToBlob(base64Data);
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
  URL.revokeObjectURL(url);
}

const base64ToBlob = async (base64Data) => {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: 'application/octet-stream' });
}

export const formatValidateCNPJCPF = (cnpjCpf) => {
  let tipoContribuinte;
  let cnpj = removeMascaraCNPJ(cnpjCpf);
  cnpj = formatCNPJMissingDigits(cnpj);

  if (!validateCNPJ(cnpj)) {
    cnpj = cnpjCpf;
    cnpj = removeMascaraCPF(cnpj);
    cnpj = formatCPFMissingDigits(cnpj);

    if (!validateCPF(cnpj)) {
      cnpj = undefined;
    } else {
      tipoContribuinte = 1;
    }
  } else {
    tipoContribuinte = 2;
  }

  return { cnpj, tipoContribuinte };
};

export const getRecurrence = () => {
  return [
    { id: 1, label: 'Única' },
    { id: 2, label: 'Mensal' }
  ];
}

export const formatCurrencyBrazilWithoutSimbol = (value) => {

  const numberValue = parseFloat(value);

  if (!isNaN(numberValue)) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numberValue);
  } else {
    return '';
  }
}

export const formatDateUTFBrazil = (dataISO) => {
  if (dataISO) {
    const [data] = dataISO.split('T');
    return `${data}T03:00:00.000Z`;
  }
  return null;
}

export const getMesNumero = (mesExtenso) => {
  const meses = getMonths();
  const mes = meses.find((m) => m.name === mesExtenso);
  return mes ? String(mes.id).padStart(2, '0') : null;
};

export const defineDueDayByIdBitrix = (dueDay) => {
  switch (dueDay) {
    case '479':
      return '01';
    case '481':
      return '02';
    case '483':
      return '03';
    case '485':
      return '04';
    case '487':
      return '05';
    case '489':
      return '06';
    case '491':
      return '07';
    case '493':
      return '08';
    case '495':
      return '09';
    case '497':
      return '10';
    case '499':
      return '11';
    case '501':
      return '12';
    case '503':
      return '13';
    case '505':
      return '14';
    case '507':
      return '15';
    case '509':
      return '16';
    case '511':
      return '17';
    case '513':
      return '18';
    case '515':
      return '19';
    case '517':
      return '20';
    case '519':
      return '21';
    case '521':
      return '22';
    case '523':
      return '23';
    case '525':
      return '24';
    case '527':
      return '25';
    case '529':
      return '26';
    case '531':
      return '27';
    case '533':
      return '28';
    case '535':
      return '29';
    case '537':
      return '30';
    case '539':
      return '31';
    default:
      return '';
  }
};

export const defineIndicationByIdBitrix = (indication) => {
  switch (indication) {
    case "331":
      return "Outplacement";
    case "333":
      return "Cliente LCR";
    case "335":
      return "2ª e/ou + empresa";
    case "337":
      return "Outros";
    default:
      return "";
  }
}

export const defineInvoicingByIdBitrix = (invoicing) => {
  switch (invoicing) {
    case "443":
      return "Mensal";
    case "445":
      return "Faturamento";
    case "447":
      return "N/A";
    default:
      return "";
  }
}

export const getStateByIdBitrix = (idBitrix) => {
  switch (idBitrix) {
    case "557": return "AC";
    case "559": return "AL";
    case "561": return "AP";
    case "563": return "AM";
    case "565": return "BA";
    case "567": return "CE";
    case "569": return "DF";
    case "571": return "ES";
    case "573": return "GO";
    case "575": return "MA";
    case "577": return "MT";
    case "579": return "MS";
    case "581": return "MG";
    case "583": return "PA";
    case "585": return "PB";
    case "587": return "PR";
    case "589": return "PE";
    case "591": return "PI";
    case "593": return "RJ";
    case "595": return "RN";
    case "597": return "RS";
    case "599": return "RO";
    case "601": return "RR";
    case "603": return "SC";
    case "605": return "SP";
    case "607": return "SE";
    case "609": return "TO";
    default: return "";
  }
}

export const getTaxRegimeByIdBitrix = (idBitrix) => {
  switch (idBitrix) {
    case "177": return "Simples Nacional";
    case "179": return "Lucro Presumido";
    case "181": return "Lucro Real";
    case "637": return "Imune/Isenta";
    default: return "";
  }
}

export const getLegalNatureByIdBitrix = (idBitrix) => {
  switch (idBitrix) {
    case "617": return "205-4 - Sociedade Anônima Fechada - JUNTA COMERCIAL";
    case "619": return "206-2 - Sociedade Empresária Limitada - JUNTA COMERCIAL";
    case "621": return "212-7 - Sociedade em Conta de Participação - Tem apenas CNPJ";
    case "623": return "213-5 - Empresário (Individual) - JUNTA COMERCIAL";
    case "625": return "223-2 - Sociedade Simples Pura - CARTÓRIO e OAB";
    case "627": return "224-0 - Sociedade Simples Limitada - CARTÓRIO";
    case "629": return "230-5 - Empresa Individual de Responsabilidade Limitada (de Natureza Empresária) - JUNTA COMERCIAL";
    case "631": return "231-3 - Empresa Individual de Responsabilidade Limitada (de Natureza Simples) - CARTÓRIO";
    case "633": return "232-1 - Sociedade Unipessoal da Advocacia - OAB";
    case "635": return "399-9 - Associação Privada - CARTÓRIO";
    default: return "";
  }
}

export const getProLaboreTypeByIdBitrix = (idBitrix) => {
  switch (idBitrix) {
    case "443": return "Mensal";
    case "445": return "Faturamento";
    case "447": return "N/A";
    default: return "";
  }
}

export const getCompanySizeByIdBitrix = (idBitrix) => {
  switch (idBitrix) {
    case "639": return "Microempreendedor Individual - MEI";
    case "641": return "Microempresa - ME";
    case "643": return "Empresa de Pequeno Porte - EPP";
    case "645": return "Empresa de Médio Porte";
    case "1483": return "Demais";
    default: return "";
  }
}
