const permissions = {
  // Automações
  "automacoes": {
    "contabil": true,
    "contabil lr": true,
    "fiscal": true,
    "fiscal sn": true,
    "fiscal lp-lr": true,
    "folha": true,
    "ot - obrigações tributarias": true,
    "ti": true,
    "lideres admin": true
  },
  "automacoes_dctfweb": {
    "contabil": true,
    "contabil lr": true,
    "fiscal": true,
    "fiscal sn": true,
    "fiscal lp-lr": true,
    "folha": true,
    "ot - obrigações tributarias": true,
    "ti": true,
    "lideres admin": true
  },
  "automacoes_das": {
    "contabil": true,
    "contabil lr": true,
    "fiscal": true,
    "fiscal sn": true,
    "fiscal lp-lr": true,
    "folha": true,
    "ot - obrigações tributarias": true,
    "ti": true,
    "lideres admin": true
  },

  "publicacoes": {
    "contabil": true,
    "contabil lr": true,
    "fiscal": true,
    "fiscal sn": true,
    "fiscal lp-lr": true,
    "financeiro": true,
    "folha": true,
    "ot - obrigações tributarias": true,
    "relacionamento": true,
    "ti": true,
    "lideres admin": true
  },

  // Publicações
  "publicacoes_impostos_mensais": {
    "contabil": true,
    "contabil lr": true,
    "fiscal": true,
    "fiscal sn": true,
    "fiscal lp-lr": true,
    "folha": true,
    "ot - obrigações tributarias": true,
    "relacionamento": true,
    "ti": true,
    "lideres admin": true
  },
  "publicacoes_publicar_lote": {
    "contabil": true,
    "contabil lr": true,
    "fiscal": true,
    "fiscal sn": true,
    "fiscal lp-lr": true,
    "folha": true,
    "ot - obrigações tributarias": true,
    "ti": true,
    "lideres admin": true
  },
  "publicacoes_impostos_conecta": {
    "contabil": true,
    "contabil lr": true,
    "fiscal": true,
    "fiscal sn": true,
    "fiscal lp-lr": true,
    "financeiro": true,
    "folha": true,
    "ot - obrigações tributarias": true,
    "relacionamento": true,
    "ti": true,
    "lideres admin": true
  },

  // Diagnóstico Fiscal Clientes
  "diagnostico": {
    "contabil": true,
    "contabil lr": true,
    "fiscal": true,
    "fiscal sn": true,
    "fiscal lp-lr": true,
    "folha": true,
    "ot - obrigações tributarias": true,
    "relacionamento": true,
    "ti": true,
    "lideres admin": true
  },

  // Dados do Cliente
  "honorarios_lcr": {
    "contabil": true,
    "contabil lr": true,
    "fiscal": true,
    "fiscal sn": true,
    "fiscal lp-lr": true,
    "financeiro": true,
    "folha": true,
    "ot - obrigações tributarias": true,
    "relacionamento": true,
    "ti": true,
    "lideres admin": true
  },

  // Configurações
  "configuracoes_clientes": {
    "relacionamento": true,
    "ti": true,
    "lideres admin": true
  },
  "configuracoes_usuarios": {
    "relacionamento": true,
    "ti": true,
    "lideres admin": true
  },
  "configuracoes_colaboradores": {
    "ti": true,
  },
  "configuracoes_departamentos": {
    "ti": true,
    "lideres admin": true
  },
  "configuracoes_aliquotas": {
    "fiscal": true,
    "fiscal sn": true,
    "fiscal lp-lr": true,
    "financeiro": true,
    "ot - obrigações tributarias": true,
    "relacionamento": true,
    "ti": true,
    "lideres admin": true
  },
  "configuracoes_empresa_servicos": {
    "fiscal": true,
    "fiscal sn": true,
    "fiscal lp-lr": true,
    "financeiro": true,
    "ot - obrigações tributarias": true,
    "relacionamento": true,
    "ti": true,
    "lideres admin": true
  },
  "configuracoes_tipo_obrigacao": {
    "contabil lr": true,
    "fiscal": true,
    "fiscal sn": true,
    "fiscal lp-lr": true,
    "folha": true,
    "ot - obrigações tributarias": true,
    "ti": true,
    "lideres admin": true
  }
};

//Automações > DARF Unificado - DCTFWEB
const actions_automacoes_dctfweb = {
  "fiscal": {
    "analista": ["export", "downloadDocument"]
  },
  "fiscal sn": {
    "analista": ["export", "downloadDocument"]
  },
  "fiscal lp-lr": {
    "analista": ["export", "downloadDocument"]
  },
  "folha": {
    "analista": ["export", "downloadDocument"]
  },
  "ot - obrigações tributarias": {
    "analista": ["export", "downloadDocument"]
  },
  "lideres admin": {
    "analista": ["edit", "export", "downloadDocument"]
  }
}

//Automações > Simples Nacional - DAS
const actions_automacoes_das = {
  "fiscal": {
    "analista": ["export", "downloadDocument"]
  },
  "fiscal sn": {
    "analista": ["export", "downloadDocument"]
  },
  "fiscal lp-lr": {
    "analista": ["export", "downloadDocument"]
  },
  "folha": {
    "analista": ["export", "downloadDocument"]
  },
  "ot - obrigações tributarias": {
    "analista": ["export", "downloadDocument"]
  },
  "lideres admin": {
    "analista": ["edit", "export", "downloadDocument"]
  }
}

const actions_publicacoes_impostos_mensais = {
  "contabil": {
    "analista": ["export"]
  },
  "contabil lr": {
    "analista": ["export"]
  },
  "fiscal": {
    "analista": ["export"]
  },
  "fiscal sn": {
    "analista": ["export"]
  },
  "fiscal lp-lr": {
    "analista": ["export"]
  },
  "folha": {
    "analista": ["export"]
  },
  "ot - obrigações tributarias": {
    "analista": ["export"]
  },
  "relacionamento": {
    "analista": ["export"]
  },
  "lideres admin": {
    "analista": ["edit", "export", "downloadDocument"]
  }
}

const actions_publicacoes_publicar_lote = {
  "fiscal": {
    "analista": ["edit", "export", "downloadDocument"]
  },
  "fiscal sn": {
    "analista": ["edit", "export", "downloadDocument"]
  },
  "fiscal lp-lr": {
    "analista": ["edit", "export", "downloadDocument"]
  },
  "folha": {
    "analista": ["edit", "export", "downloadDocument"]
  },
  "ot - obrigações tributarias": {
    "analista": ["edit", "export", "downloadDocument"]
  },
  "lideres admin": {
    "analista": ["edit", "export", "downloadDocument"]
  }
}

const actions_publicacoes_impostos_conecta = {
  "contabil": {
    "analista": ["export"]
  },
  "contabil lr": {
    "analista": ["edit", "export", "downloadDocument"]
  },
  "fiscal": {
    "analista": ["edit", "export", "downloadDocument"]
  },
  "fiscal sn": {
    "analista": ["edit", "export", "downloadDocument"]
  },
  "fiscal lp-lr": {
    "analista": ["edit", "export", "downloadDocument"]
  },
  "financeiro": {
    "analista": ["export"]
  },
  "folha": {
    "analista": ["edit", "export", "downloadDocument"]
  },
  "ot - obrigações tributarias": {
    "analista": ["edit", "export", "downloadDocument"]
  },
  "relacionamento": {
    "analista": ["export"]
  },
  "lideres admin": {
    "analista": ["edit", "export", "downloadDocument"]
  }
}

const actions_diagnostico = {
  "ot - obrigações tributarias": {
    "analista": ["edit", "export", "downloadDocument"]
  },
  "lideres admin": {
    "analista": ["edit", "export", "downloadDocument"]
  }
}

const actions_honorarios_lcr = {
  "financeiro": {
    "analista": ["edit", "export", "downloadDocument"]
  },
}

const actions_configuracoes_clientes = {}

const actions_configuracoes_usuarios = {
  "relacionamento": {
    "analista": ["edit", "export", "downloadDocument"]
  }
}

const actions_configuracoes_colaboradores = {
  "ti": {
    "analista": ["edit", "export", "downloadDocument"]
  }
}

const actions_configuracoes_departamentos = {
  "ti": {
    "analista": ["edit", "export", "downloadDocument"]
  },
  "lideres admin": {
    "analista": ["edit", "export", "downloadDocument"]
  }
}

const actions_configuracoes_aliquotas = {
  "lideres admin": {
    "analista": ["edit", "export", "downloadDocument"]
  }
}

const actions_configuracoes_empresa_servicos = {
  "lideres admin": {
    "analista": ["edit", "export", "downloadDocument"]
  }
}

const actions_configuracoes_tipo_obrigacao = {
  "lideres admin": {
    "analista": ["edit", "export", "downloadDocument"]
  }
}

const permissionsActions = {
  actions_automacoes_dctfweb,
  actions_automacoes_das,
  actions_publicacoes_impostos_mensais,
  actions_publicacoes_publicar_lote,
  actions_publicacoes_impostos_conecta,
  actions_diagnostico,
  actions_honorarios_lcr,
  actions_configuracoes_clientes,
  actions_configuracoes_usuarios,
  actions_configuracoes_colaboradores,
  actions_configuracoes_departamentos,
  actions_configuracoes_aliquotas,
  actions_configuracoes_empresa_servicos,
  actions_configuracoes_tipo_obrigacao
}

export const verifyUserHasAccess = (user, screen) => {
  if (user.funcao === 'admin') {
    return true;
  }

  if (!permissions[screen]) {
    return false;
  }

  const department = user.departamento ? user.departamento.toLowerCase() : "";

  return permissions[screen]?.[department] || false;
};

export const verifyUserPermissionAction = (screen, user, action) => {
  if (user.funcao === 'admin') {
    return true;
  }

  const department = user.departamento ? user.departamento.toLowerCase() : "";

  const fullScren = 'actions_' + screen;
  return permissionsActions[fullScren]?.[department]?.[user.funcao]?.includes(action) || false;
};
