import { lazy, useContext } from 'react';

import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import RequireAuth from "./requireAuth";

import { AuthContext } from '../contexts/auth';

const ClientEvoluteAnalysis = Loadable(lazy(() => import('views/dashboard/ClientEvoluteAnalysis')));
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const DashboardAdmin = Loadable(lazy(() => import('views/dashboard/Admin')));

const Colaboradores = Loadable(lazy(() => import('views/Configuracoes/Colaboradores')));
const Clientes = Loadable(lazy(() => import('views/Configuracoes/Clientes')));
const Departamentos = Loadable(lazy(() => import('views/Configuracoes/Departamentos')));
const Usuario = Loadable(lazy(() => import('views/Configuracoes/Usuario')));
const Aliquotas = Loadable(lazy(() => import('views/Configuracoes/Aliquotas')));
const EmpresaServicos = Loadable(lazy(() => import('views/Configuracoes/EmpresaServicos')));
const TipoObrigacao = Loadable(lazy(() => import('views/Configuracoes/TipoObrigacao')));

const Ecac = Loadable(lazy(() => import('views/Rotinas/ECAC')));
const Das = Loadable(lazy(() => import('views/Rotinas/DAS')));
const ClientPanel = Loadable(lazy(() => import('views/Rotinas/ClientPanel')));
const ImpostosMensaisPanel = Loadable(lazy(() => import('views/Rotinas/ImpostosGeral')));
const PublicarObrigacoesPanel = Loadable(lazy(() => import('views/Rotinas/PublicarObrigacoes')));
const ImpostosMensaisConectaPanel = Loadable(lazy(() => import('views/Rotinas/ImpostosMensaisConecta')));

const Chamados = Loadable(lazy(() => import('views/menuCliente/Chamados')));
const Mensalidades = Loadable(lazy(() => import('views/menuCliente/Mensalidades')));
const MensalidadesAtuais = Loadable(lazy(() => import('views/menuCliente/MensalidadesAtuais')));
const MeusImpostos = Loadable(lazy(() => import('views/menuCliente/MeusImpostos')));
const MeusImpostosAtuais = Loadable(lazy(() => import('views/menuCliente/MeusImpostosAtuais')));
const MeusArquivos = Loadable(lazy(() => import('views/menuCliente/MeusArquivos')));
const MeusCompromissos = Loadable(lazy(() => import('views/menuCliente/MeusCompromissos')));
const EmissaoNF = Loadable(lazy(() => import('views/menuCliente/EmissaoNF')));
const MeusFavorecidos = Loadable(lazy(() => import('views/menuCliente/MeusFavorecidos')));
const MinhasNF = Loadable(lazy(() => import('views/menuCliente/MinhasNF')));

const DashboardRoute = () => {
  const { user } = useContext(AuthContext);

  const DashboardComponent = (() => {
    switch (user.funcao) {
      case "cliente":
        return DashboardDefault;
      case "admin":
        return DashboardAdmin;
      case "analista":
        return DashboardAdmin;
      default:
        return Chamados;
    }
  })();

  return (
    <RequireAuth redirectTo="/login" isPrivate>
      <DashboardComponent />
    </RequireAuth>
  );
};

const MainRoutes = {
  path: '/',
  element: <RequireAuth redirectTo="/login" isPrivate><MainLayout /> </RequireAuth>,
  children: [
    {
      path: '/',
      element: <RequireAuth redirectTo="/login" isPrivate><DashboardRoute /></RequireAuth>
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <RequireAuth redirectTo="/login" isPrivate><DashboardDefault /></RequireAuth>
        },
        {
          path: 'evoluteAnalysis',
          element: <RequireAuth redirectTo="/login" isPrivate><ClientEvoluteAnalysis /></RequireAuth>
        },
        {
          path: 'admin',
          element: <RequireAuth redirectTo="/login" isPrivate><DashboardAdmin /></RequireAuth>
        }
      ]
    },
    {
      path: 'configuracoes',
      children: [
        { path: 'colaboradores', element: <RequireAuth redirectTo="/login" isPrivate><Colaboradores /></RequireAuth> },
        { path: 'clientes', element: <RequireAuth redirectTo="/login" isPrivate><Clientes /></RequireAuth> },
        { path: 'departamentos', element: <RequireAuth redirectTo="/login" isPrivate><Departamentos /></RequireAuth> },
        { path: 'usuario', element: <RequireAuth redirectTo="/login" isPrivate><Usuario /></RequireAuth> },
        { path: 'aliquotas', element: <RequireAuth redirectTo="/login" isPrivate><Aliquotas /></RequireAuth> },
        { path: 'empresaServicos', element: <RequireAuth redirectTo="/login" isPrivate><EmpresaServicos /></RequireAuth> },
        { path: 'tipoObrigacao', element: <RequireAuth redirectTo="/login" isPrivate><TipoObrigacao /></RequireAuth> },
      ]
    },

    {
      path: 'cliente',
      children: [
        { path: 'chamados', element: <RequireAuth redirectTo="/login" isPrivate><Chamados /></RequireAuth> },
        { path: 'mensalidades', element: <RequireAuth redirectTo="/login" isPrivate><Mensalidades /></RequireAuth> },
        { path: 'mensalidadesAtuais', element: <RequireAuth redirectTo="/login" isPrivate><MensalidadesAtuais /></RequireAuth> },
        { path: 'impostos', element: <RequireAuth redirectTo="/login" isPrivate><MeusImpostos /></RequireAuth> },
        { path: 'impostosAtuais', element: <RequireAuth redirectTo="/login" isPrivate><MeusImpostosAtuais /></RequireAuth> },
        { path: 'arquivos', element: <RequireAuth redirectTo="/login" isPrivate><MeusArquivos /></RequireAuth> },
        { path: 'compromissos', element: <RequireAuth redirectTo="/login" isPrivate><MeusCompromissos /></RequireAuth> },
        { path: 'emissaonf', element: <RequireAuth redirectTo="/login" isPrivate><EmissaoNF /></RequireAuth> },
        { path: 'favorecidos', element: <RequireAuth redirectTo="/login" isPrivate><MeusFavorecidos /></RequireAuth> },
        { path: 'minhasNF', element: <RequireAuth redirectTo="/login" isPrivate><MinhasNF /></RequireAuth> }
      ]
    },
    {
      path: 'rotinas',
      children: [
        { path: 'ecac', element: <RequireAuth redirectTo="/login" isPrivate><Ecac /></RequireAuth> },
        { path: 'das', element: <RequireAuth redirectTo="/login" isPrivate><Das /></RequireAuth> },
        { path: 'clientPanel', element: <RequireAuth redirectTo="/login" isPrivate><ClientPanel /></RequireAuth> },
        { path: 'impostosMensais', element: <RequireAuth redirectTo="/login" isPrivate><ImpostosMensaisPanel /></RequireAuth> },
        { path: 'publicarObrigacoes', element: <RequireAuth redirectTo="/login" isPrivate><PublicarObrigacoesPanel /></RequireAuth> },
        { path: 'impostosMensaisConecta', element: <RequireAuth redirectTo="/login" isPrivate><ImpostosMensaisConectaPanel /></RequireAuth> }

      ]
    }
  ]
};

export default MainRoutes;
